// import logo from './logo.svg';
import "./App.scss";

const meta = {
  title: window.props.title ?? 'Geen titel',
  content: window.props.content ?? 'Geen content',
  excerpt: window.props.content ?? 'Geen excerpt',
  thumbnail: window.props.content ?? 'Geen thumbnail',
}

function App() {
  return (
    <div className="App">
      <div dangerouslySetInnerHTML={{__html: meta.content }} />
    </div>
  );
}

export default App;
