import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/**
 * Load wp_head() into the head section
 */
fetch('https://doorstroomcoach.nl/index.php?rest_route=/max/v1/head')
  .then(response => response.text())
  .then(data => {
      const titletag = `<title>Doorstroom coach - ${window.props.title} - Gerard de Bresser</title>`
      document.head.innerHTML = titletag + document.head.innerHTML + data;
  });
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
